<template>
  <div class="common-wrapper">
    <!-- Android -->
    <el-row v-if="show">
      <!-- 查询 -->
      <el-row :gutter="4" class="elRow" style="display: flex">
        <el-col :span="3">
          <el-input
            size="small"
            :placeholder="$t('DeviceManage.TerminalVersionPage.placeholder')"
            v-model.trim="keywords"
            @keyup.native="btKeyUp"
            @keyup.enter.native="search"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="small" v-debounce:500="search">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow">
        <el-col :span="3">
          <el-button-group>
            <el-button
              style="border-radius: 3px 0px 0px 3px"
              type="primary"
              size="small"
              @click="addNew"
              >{{ $t("public.New") }}</el-button
            >
            <el-button
              style="border-radius: 0px 3px 3px 0px"
              @click="delArr(delarr)"
              :type="typeInfo"
              :disabled="!hasSeleted"
              size="small"
              >{{ $t("public.Delete") }}</el-button
            >
          </el-button-group>
        </el-col>
      </el-row>
      <el-row :gutter="0" class="elRow">
        <el-table
          size="mini"
          :row-key="getRowKeys"
          stripe
          ref="multipleTable"
          :data="tableData"
          class="tableClass"
          tooltip-effect="dark"
          style="width: 100%;"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          :default-sort="{ prop: 'versionName', order: 'descending' }"
          @sort-change="sort"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            :reserve-selection="true"
            align="center"
            type="selection"
            width="60"
          >
          </el-table-column>

          <el-table-column
            sortable="custom"
            prop="versionName"
            :label="$t('public.Version')"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template v-slot="{ row }">
              {{ row.versionName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <img
                @click="edit(row)"
                :title="$t('public.Editor')"
                src="@/assets/icon_write.png"
                alt=""
                style="width: 15px; height: 15px; cursor: pointer"
              />
              <img
                @click="details(row.id)"
                :title="$t('public.Delete')"
                src="@/assets/icon_del.png"
                alt=""
                style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  cursor: pointer;
                "
              />
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        />
      </el-row>
    </el-row>
    <!-- 编辑的框 -->
    <el-row v-if="!show">
      <el-form
        @submit.native.prevent
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('public.Version') + '：'" prop="version">
          <el-input
            v-model.trim="ruleForm.version"
            size="small"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            @click="submitForm('ruleForm')"
            >{{ $t("public.Save") }}</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 保存成功提示组件 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import naviGation from "@/components/hearder/index"; //头部组件
import dialogInfo from "@/components/promptMessage/index";
import pagination from "@/components/pagination/page.vue"; //分页
export default {
  components: {
    naviGation,
    pagination,
    dialogInfo,
  },
  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      // 分页
      page: {
        total: 0, //总条数
        pageSize: 10, //每页条数,  默认10条
        currentPage: 1,
      },
      show: true, //显示隐藏
      action: "",
      record: "", //弹框总数记录
      delarr: [], //存放要删除的数据
      gridData: [], //弹框数组
      keywords: "",
      ruleForm: {
        editId: "",
        version: "",
        editCreateTime: "", //编辑时间
      },
      titleInfo: "", // 提示框的提示语
      tableData: [],
      orderRule: "DESC",
      orderColume: "versionName",
      flagDisable: false, // 遮罩全选
      isCheckShow: false,
      visibaelFlag: false, //提示消息默认--关闭
      rules: {
        version: [
          {
            required: true,
            message: this.$t("DeviceManage.TerminalVersionPage.versionVoid"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (/^([1-9]\d|[1-9])(\.([1-9]\d|\d)){2}$/.test(value) == false) {
                callback(
                  new Error(
                    this.$t("DeviceManage.TerminalVersionPage.versionReg")
                  )
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
          {
            validator: (rule, value, callback) => {
              if (/^\d+(.\d+)*$/.test(value) == false) {
                callback(
                  new Error(
                    this.$t("DeviceManage.TerminalVersionPage.versionReg")
                  )
                );
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
      },
    };
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },
  mounted() {
    this.getDatalist();
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // // 关闭提示框
    handleClose() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //   列表
    async getDatalist() {
      var parma = {
        osType: 10,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        versionName: this.keywords,
      };
      console.log(parma);
      const res = await this.$axios.post(
        "/httpServe/clientOsVersionConfig/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },
    // 查询
    async search() {
      var parma = {
        osType: 10,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        versionName: this.keywords,
      };
      const res = await this.$axios.post(
        "/httpServe/clientOsVersionConfig/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.page.total = res.data.total;
      this.page.currentPage = 1;
    },
    // 新增
    async addNew() {
      this.show = false;
      this.ruleForm.version = "";
      this.action = "add"; //保存时来判断新增1还是编辑2
    },
    //新增保存
    async addSave() {
      var parma = {
        ids: [],
        osType: "10",
        versionName: this.ruleForm.version,
      };
      const res = await this.$axios.post(
        "/httpServe/clientOsVersionConfig/save",
        parma,
        true
      );
      console.log(res, "xinzeng");
      if (res.data == -1) {
        this.$message({
          message: this.$t(
            "DeviceManage.TerminalVersionPage.SaveFailedVerNumRepeat"
          ),
          type: "error",
          offset: 100,
        });
      } else if (res.data == 0) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.show = true;
        this.ruleForm.version = "";
        this.getDatalist();
      }
    },
    // 编辑
    edit(row) {
      console.log(row, "编辑");
      this.show = false;
      this.action = "edit";
      this.ruleForm.editId = row.id;
      this.ruleForm.version = row.versionName;
    },

    //编辑保存
    async editSave() {
      var parma = {
        ids: [this.ruleForm.editId],
        osType: "10",
        versionName: this.ruleForm.version,
      };
      const res = await this.$axios.post(
        "/httpServe/clientOsVersionConfig/save",
        parma,
        true
      );
      if (res.data == -1) {
        this.$message({
          message: this.$t(
            "DeviceManage.TerminalVersionPage.SaveFailedVerNumRepeat"
          ),
          type: "error",
          offset: 100,
        });
      } else if (res.data == 0) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.show = true;
        this.ruleForm.version = "";
        this.getDatalist();
      }
    },
    // 编辑的非空校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.action == "add") {
            this.addSave();
          } else if (this.action == "edit") {
            this.editSave();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSelectionChange(obj) {
      this.delarr = obj;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    // 删除单条
    details(id) {
      var params = {
        ids: [id],
      };

      this.$confirm(
        this.$t("DeviceManage.TerminalVersionPage.sureDeleteSeleVersion"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          this.$axios
            .post("/httpServe/clientOsVersionConfig/delete", params, true)
            .then(() => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.$refs.multipleTable.clearSelection();
              this.getDatalist();
            })
            .catch(() => {
              this.$message({
                message: this.$t("DeviceManage.TerminalVersionPage.SaveFailed"),
                type: "error",
                offset: 100,
              });
            });
        })
        .catch(() => {
          return false;
        });
    },
    //多选删除
    delArr(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id.toString());
      });
      var params = {
        ids: ids,
      };

      this.$confirm(
        this.$t("DeviceManage.TerminalVersionPage.sureDeleteSeleVersion"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          this.$axios
            .post("/httpServe/clientOsVersionConfig/delete", params, true)
            .then(() => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.$refs.multipleTable.clearSelection();
              this.getDatalist();
            })
            .catch(() => {
              this.$message({
                message: this.$t("DeviceManage.TerminalVersionPage.SaveFailed"),
                type: "error",
                offset: 100,
              });
            });
        })
        .catch(() => {
          return false;
        });
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // 调用查询方法
      this.search();
    },

    // 排序
    sort(column) {
      if (column.order == "ascending") {
        this.orderRule = "ASC";
      } else {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.getDatalist();
    },
  },
};
</script>

<style lang="scss" scoped>
.common-wrapper {
  width: 100%;
  height: 100%;
  padding: 0px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>