<template>
  <div class="terminalversion">
    <!-- 面包屑 -->
    <navi-gation />
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
      <el-tab-pane label="Android" name="1">
        <appAndroid :key="timer1" v-if="isChildUpdate1"></appAndroid>
      </el-tab-pane>
      <el-tab-pane label="iOS" name="2">
        <appIOS :key="timer2" v-if="isChildUpdate2"></appIOS>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import appIOS from './components/AppLibIOS.vue'
import naviGation from '@/components/hearder/index'
import Eldialog from '@/components/elDialog/index.vue'
import appAndroid from './components/AppLibAndroid.vue'
import pagination from '@/components/pagination/page.vue'
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: {
    appIOS,
    Eldialog,
    naviGation,
    pagination,
    dialogInfo,
    appAndroid
  },
  data() {
    return {
      timer1: '',
      timer2: '',
      activeName: '1',
      isChildUpdate1: true,
      isChildUpdate2: false
    }
  },
  methods: {
    handleLoad(data) {
      let name = data.name
      if (name == 1) {
        this.timer1 = new Date().getTime()
        this.isChildUpdate1 = true
        this.isChildUpdate2 = false
      } else if (name == 2) {
        this.timer2 = new Date().getTime()
        this.isChildUpdate1 = false
        this.isChildUpdate2 = true
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}
::v-deep .el-tabs__item {
  padding: 0 12px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
::v-deep .el-tabs__item.is-active {
  color: #000;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
</style>
<style>
.el-input1 {
  width: 200px;
}
.btn {
  margin-left: 10px;
  margin-right: 1px;
}
.el-button + .el-button {
  margin-left: 0px;
}
</style>
